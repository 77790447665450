import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import EvtoolsTour from '@evtools/tour'
import { currentTheme } from './services/currentTheme'
import i18n from '@/plugins/i18n'

// custom css
import '@/assets/sass/main.scss'

// Load css-variables based on the current theme //
export const loadThemeCss = async ({ path }) => {
  const theme = currentTheme({ path })
  await import(`/theme-css/${theme.details.cssFile}`)

  store.dispatch('config/saveCurrentTheme', { theme })
  return theme
}

Vue.config.productionTip = false

// Bugfender
// import { Bugfender } from '@bugfender/sdk'
// try {
//   Bugfender.init({
//     appKey: process.env.VUE_APP_BUGFENDER_API_KEY,
//     build: process.env.VUE_APP_BUGFENDER_BUILD || 'none',
//   })
// } catch (e) {
//   // eslint-disable-next-line no-console
//   console.log(e)
// }

// Polyfill Fetch
import 'whatwg-fetch'

// Bootstrap - TODO: Use Bootstrap treeshaking plugins
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(EvtoolsTour)

// Auth0 - TODO: Move plugin code to npm module
import { Auth0Plugin } from './auth'
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENTID,
  audience: process.env.VUE_APP_AUTH_AUDIENCE,
  onRedirectCallback: appState => {
    loadThemeCss({ path: appState.targetUrl })

    router.push(
      appState?.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})


/**
 * Vue notifications
 * todo:: use more lightweight notification system
 */
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
Vue.use(Notifications, { velocity })

/**
 * Init Geocoder
 */
import { initGoogleAPILoader } from '../shared/services/geocoder'
initGoogleAPILoader()

const v1customers = ['']
const subdomain = window.location.host.split('.')[2] ? window.location.host.split('.')[0] : false
if (subdomain && v1customers.includes(subdomain)) {
  let hostWithoutSubdomain = window.location.host.split('.').slice(1).join('.')
  window.location.href = `${window.location.protocol}//${hostWithoutSubdomain}${window.location.pathname}`
} else {
  const instance = new Vue({
    router,
    store,
    render: h => h(App),
    i18n,
  }).$mount('#app')

  // bind auth0 Plugin to the store, so we can retrieve there the token as well
  instance.$store.$auth = instance.$auth
  instance.$store.$bvToast = instance.$bvToast
}
if (!store.getters['config/getCurrentTheme']) {
  loadThemeCss({ path: window.location.pathname })
}
