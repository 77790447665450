<template>
  <div>
    <evtools-tour
      v-if="isAppReady"
      :tour-name="tourName"
      :steps="steps"
      :begin="begin"
      route="Viewer"
      @start-tour="begin = true"
    >
      <template #introduction>
        <p>
          Graag informeren wij u over een nieuwe functionaliteit die is
          toegevoegd aan Maps:
        </p>

        <ul>
          <li>Meetlint met afstand</li>
        </ul>

        <p>Feedback, suggesties en vragen zijn welkom op <MailtoSupport />.</p>
      </template>
    </evtools-tour>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MailtoSupport from '@/components/common/MailtoSupport'

export default {
  name: 'RulerTour',
  components: {
    MailtoSupport,
  },
  data() {
    return {
      tourName: 'ruler-tour',
      begin: false,
    }
  },
  computed: {
    ...mapGetters('app', ['isAppReady']),
    steps() {
      return [
        {
          target: '.icon-text-wrapper',
          header: {
            title: 'Meetlint',
          },
          content: `
            <p>Gebruik het meetlint om de afstand tussen punten op de kaart te zien.</p>
            <ul>
                <li>Klik op twee of meerdere punten op de kaart om een lijn te trekken.</li>
                <li>Om een gemeten punt op de kaart te verwijderen, klikt u op het betreffende punt.</li>
                <li>U kunt de meting sluiten door nogmaals op het meetlint icoon te klikken, metingen worden niet opgeslagen.</li>
                <li>De totale afstand tussen de gemeten punten wordt onder deze knop weergegeven wanneer er een lijn is getekend.</li>
            </ul>
            `,
          params: {
            placement: 'top',
          },
        },
      ]
    },
  },
}
</script>
