<template>
  <div class="ActiveTab MergeTab">
    <LocatieInformatieHeader :chargingpoint="chargingpoint">
      <div class="ActiveTab__Header__Tooltip-wrapper">
        <h4>{{ $t('components.sidebar.MergeTab.title') }}</h4>
        <SvgIcon
          id="title-info-icon"
          class="SvgIcon__Info"
          icon="info-circle-regular"
        />

        <b-tooltip
          target="title-info-icon"
          :title="$t('components.sidebar.MergeTab.titleTooltip')"
        />
      </div>
    </LocatieInformatieHeader>

    <div
      v-if="hasPossibleDuplicates"
      class="ActiveTab__Content d-flex flex-column"
    >
      <b-alert
        :show="anyLocationFromRHDHV && isLockedForEditing"
        variant="warning"
      >
        {{ $t('components.sidebar.Tabs.CommentsTab.royalHaskoningMSG') }}
      </b-alert>

      <p>
        {{ $t('components.sidebar.MergeTab.mergeDescription') }}
      </p>
      <b-form-group
        :label="$t('components.sidebar.MergeTab.mergeWith')"
      >
        <b-form-radio-group
          v-model="selected"
        >
          <div
            v-for="duplicate in possibleDuplicates"
            :key="duplicate.data.uuid"
            class="d-flex justify-content-between align-items-center"
          >
            <b-form-radio
              :value="duplicate.data.uuid"
            >
              <InlineChargingpoint :chargingpoint="duplicate" />
            </b-form-radio>
            <b-icon-slash-circle
              v-b-tooltip.hover="$t('components.sidebar.MergeTab.rejectButtonTooltip')"
              class="reject-merge-button"
              role="button"
              @click="selectReject(duplicate.data.uuid)"
            />
          </div>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group
        v-if="selected"
        :label="$t('components.sidebar.MergeTab.copyCoordinates')"
      >
        <b-form-radio-group
          v-model="useCoordinatesFrom"
        >
          <div
            class="d-flex justify-content-between align-items-center mb-2"
          >
            <b-form-radio
              :value="selected"
            >
              <InlineChargingpoint :chargingpoint="selectedChargingpoint">
                <div class="text-muted ml-4">
                  {{ selectedAddressSource }}
                </div>
              </InlineChargingpoint>
            </b-form-radio>
          </div>
          <div
            class="d-flex justify-content-between align-items-center"
          >
            <b-form-radio
              :value="chargingpoint.data.uuid"
            >
              <InlineChargingpoint :chargingpoint="chargingpoint">
                <div class="text-muted ml-4">
                  {{ thisAddressSource }}
                </div>
              </InlineChargingpoint>
            </b-form-radio>
          </div>
        </b-form-radio-group>
      </b-form-group>
      <b-form-textarea
        v-model="reason"
        class="mt-3"
        size="sm"
        :placeholder="$t('components.sidebar.MergeTab.placeholderMotive')"
        rows="1"
        max-rows="6"
      />
      <div class="mt-3 ml-auto">
        <b-button
          v-if="selected"
          size="sm"
          variant="secondary"
          @click="reset"
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button
          type="submit"
          size="sm"
          variant="primary"
          class="ml-2"
          :disabled="mergeIsDisabled"
          @click="acceptMerge"
        >
          {{ $t('components.sidebar.MergeTab.mergeButton') }}
        </b-button>
      </div>
    </div>

    <div
      v-else
      class="ActiveTab__Content"
      v-html="$t('components.sidebar.MergeTab.mergeImpossible')"
    />

    <b-modal
      ref="confirm-reject"
      title="Duplicaat weghalen"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <p>
        Weet u zeker dat u deze markering als mogelijk duplicaat wilt weghalen? Hierna is het niet meer mogelijk om de locaties samen te voegen.
      </p>
      <template #modal-footer>
        <b-button
          variant="light"
          @click="cancelReject"
        >
          Annuleren
        </b-button>
        <b-button
          variant="primary"
          @click="confirmReject"
        >
          Bevestigen
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InlineChargingpoint from '@/components/common/InlineChargingpoint'
import { BIconSlashCircle } from 'bootstrap-vue'
import { SOURCE, sourceToSlug } from '@/services/sourceTranslation'
import { checkStatus, returnJson } from '@/helpers/api'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'
import SvgIcon from '@/components/common/SvgIcon.vue'
import { EventBus } from '@/services/eventbus'
import { ACTION_SIDEBAR_TAB } from '../../../../../shared/valueholders/action-sidebar-tabs'

export default {
  components: { SvgIcon, LocatieInformatieHeader, BIconSlashCircle, InlineChargingpoint },
  data() {
    return {
      reason: '',
      selected: '',
      useCoordinatesFrom: '',
      toBeRejected: '',
    }
  },
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
      possibleDuplicates: 'getPossibleDuplicatesOfSelectedChargingpoint',
    }),
    isLockedForEditing() {
      return this.chargingpoint.data.isLockedForEditing || this.workflowLocation
    },
    workflowLocation () {
      return !!this.chargingpoint.data.workflowCaseRef || !!this.chargingpoint.data.workflowUuid
    },
    hasPossibleDuplicates() {
      return this.possibleDuplicates.length > 0
    },
    selectedChargingpoint() {
      return this.possibleDuplicates.find(duplicate => duplicate.data.uuid === this.selected)
    },
    thisAddressSource() {
      return sourceToSlug({ source: this.chargingpoint.data.address?.source })
    },
    selectedAddressSource() {
      return sourceToSlug({ source: this.selectedChargingpoint?.data.address?.source })
    },
    mergeIsDisabled() {
      return ! (this.selected && this.useCoordinatesFrom) || (this.anyLocationFromRHDHV && this.isLockedForEditing)
    },
    anyLocationFromRHDHV() {
      return this.thisAddressSource === sourceToSlug({ source: SOURCE.RHDHV })
          || this.selectedAddressSource === sourceToSlug({ source: SOURCE.RHDHV })
    },
  },
  watch: {
    chargingpoint() {
      this.reset()
    },
    // Reset the displayed data when the possible duplicates change (because of f.e. status filter changes
    possibleDuplicates() {
      this.reset()
    },
  },
  methods: {
    ...mapActions('app', ['selectTab']),
    ...mapActions('planmode', [
      'mergeChargingpoints',
      'removePossibleDuplicates',
      'showLinesToPossibleDuplicates',
    ]),
    reset() {
      this.selected = ''
      this.useCoordinatesFrom = ''
      this.showLinesToPossibleDuplicates()
    },
    showModal() {
      this.$refs['confirm-reject'].show()
    },
    hideModal() {
      this.$refs['confirm-reject'].hide()
    },
    selectReject(uuid) {
      this.toBeRejected = uuid
      this.showModal()
    },
    cancelReject() {
      this.toBeRejected = ''
      this.hideModal()
    },
    confirmReject() {
      this.hideModal()
      this.rejectMerge(this.toBeRejected)
      this.toBeRejected = ''
    },
    async acceptMerge() {
      try {
        const token = await this.$auth.getTokenSilently()
        const result = await fetch('/api/mergeaccept', {
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            chargingpoints: [
              this.chargingpoint.data.uuid,
              this.selected,
            ],
            useCoordinatesFrom: this.useCoordinatesFrom,
            reason: this.reason || undefined,
          }),
        })
          .then(checkStatus)
          .then(returnJson)

        this.mergeChargingpoints({
          chargingpoints: [
            this.chargingpoint.data.uuid,
            this.selected,
          ],
          result: result.chargingpoint,
        })

        // open newly merged chargingpoint
        EventBus.$emit('select-chargingpoint', result.chargingpoint.ref['@ref'].id)
        this.selectTab({ tabName: ACTION_SIDEBAR_TAB.OPMERKINGEN })

        this.$notify({
          type: 'success',
          title: 'Gelukt!',
          text: 'De locaties zijn samengevoegd.',
        })
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'Het samenvoegen van de locaties is niet gelukt. Probeer het later opnieuw.',
        })
      }

    },
    async rejectMerge(rejectedDuplicate) {
      try {
        const token = await this.$auth.getTokenSilently()
        await fetch('/api/mergereject', {
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            chargingpoints: [
              this.chargingpoint.data.uuid,
              rejectedDuplicate,
            ],
          }),
        })
          .then(checkStatus)

        this.removePossibleDuplicates({
          uuid1: this.chargingpoint.data.uuid,
          uuid2: rejectedDuplicate,
        })

        this.selectTab({ tabName: ACTION_SIDEBAR_TAB.OPMERKINGEN })

        this.showLinesToPossibleDuplicates()

        this.$notify({
          type: 'success',
          title: 'Gelukt!',
          text: 'Het duplicaat is weggehaald.',
        })
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'Het weghalen van het duplicaat is niet gelukt. Probeer het later opnieuw.',
        })
      }

    },
  },
}
</script>

<style scoped lang="scss">
.ActiveTab__Header__Tooltip-wrapper {
  display: flex;

  .SvgIcon__Info {
    margin-left: 0.25rem;
    margin-top: 0.25rem;
  }
}

.ActiveTab__Content {
  flex-grow: 1;
  p {
    margin-bottom: 0.5rem;
  }
}

.reject-merge-button {
  font-size: 25px;
  margin-bottom: .25rem;
  padding: .25rem;
  width: 30px;
  border-radius: 5px;
  color: white;
  border: 1px solid var(--primary);
  background: var(--primary);
}

.custom-control-inline.custom-radio {
  margin-right: .5rem;
}

.InlineChargingpoint {
  max-width: 209px;
}
</style>
