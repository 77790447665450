const {
  CHARGINGPOINT_ACCESSIBILITY,
  CHARGINGPOINT_CAPACITY,
  CHARGINGPOINT_STATUS,
} = require('../../shared/valueholders/chargingpoint-statuses')
const { USER_ROLE } = require('../../shared/valueholders/userRoles')

import { statusSlugToLabel } from '@/../shared/services/statusTranslations'

export const planmodeLayerGroups = [
  /**
   * Planmode
   */
  {
    id: 'realized',
    label: 'groupLabels.realized',
    visible: true,
    ignore: true,
    config: {
      layerMatrix: true,
      legendCollapseOpen: true, // collapsible in legend open per default
      status: CHARGINGPOINT_STATUS.REALIZED,
      iconColor: '#8C2FAB',
      phase: 'realization-phase',
    },
  },
  {
    id: 'in-progress',
    label: 'groupLabels.in-progress',
    visible: true,
    ignore: true,
    config: {
      layerMatrix: true,
      legendCollapseOpen: false,
      status: CHARGINGPOINT_STATUS.IN_PROGRESS,
      iconColor: '#e840e2',
      phase: 'realization-phase',
    },
  },
  {
    id: 'definitive',
    label: 'groupLabels.definitive',
    visible: true,
    ignore: true,
    config: {
      layerMatrix: true,
      legendCollapseOpen: false,
      status: CHARGINGPOINT_STATUS.DEFINITIVE,
      iconColor: '#325E00',
      phase: 'planning-phase',
      iconType: 'point',
    },
  },
  {
    id: 'suggestion',
    label: 'groupLabels.suggestion',
    visible: true,
    ignore: true,
    config: {
      layerMatrix: true,
      legendCollapseOpen: false,
      status: CHARGINGPOINT_STATUS.SUGGESTION,
      iconColor: '#D19332',
      phase: 'planning-phase',
      iconType: 'point',
    },
  },
  {
    id: 'rejected',
    label: 'groupLabels.rejected',
    visible: true,
    ignore: true,
    config: {
      layerMatrix: true,
      legendCollapseOpen: false,
      status: CHARGINGPOINT_STATUS.REJECTED,
      iconColor: '#B53A3A',
      phase: 'planning-phase',
      iconType: 'point',
    },
  },
  {
    id: 'alert',
    label: 'groupLabels.alert',
    visible: true,
    ignore: true,
    tooltip: 'Dit punt markeert een aandachtspunt',
    config: {
      layerMatrix: false,
      legendCollapseOpen: false,
      status: CHARGINGPOINT_STATUS.ALERT,
      iconColor: 'black',
    },
  },
]

export const planmodeLayerRestrictions = [
  {
    status: CHARGINGPOINT_STATUS.SUGGESTION,
    capacity: CHARGINGPOINT_CAPACITY.FASTCHARGER,
    restrictions: {
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
]

// todo:: needs refactor to be more generic, if there are more restrictions / could be a reduce function I guess
const findRestriction = ({ group, capacity }) => {
  return planmodeLayerRestrictions.find(restriction => restriction.status === group.id && restriction.capacity === capacity)?.restrictions
}

/**
 * loop over all types and build layer array
 *
 * for backwards compatibility, we have to make some exceptions in the status definition, see comments below
 */
export const planmodeLayerDetails = planmodeLayerGroups.reduce((layers, group) => {
  if (! group.config.layerMatrix) {
    layers.push({
      id: `chargingpoints-${group.id}`,
      label: statusSlugToLabel(group.id, localStorage.getItem('language')) ?? 'label niet gevonden',
      type: 'planmode',
      visible: group.visible,
      config: {
        status: group.id,
        statusGroup: group.id,
        iconName: group.id,
        iconColor: group.config.iconColor,
        phase: group.config.phase,
      },
      restrictions: findRestriction({ group, capacity: null }),
    })

    return layers
  }

  Object.values(CHARGINGPOINT_ACCESSIBILITY).forEach(accessibility => {
    Object.values(CHARGINGPOINT_CAPACITY).forEach(capacity => {
      /**
       * public and regular locations (default statuses) are never modified
       * public, non-regular locations are not modified with the accessibility
       * all other locations get the full modified version
      */
      const access = accessibility !== CHARGINGPOINT_ACCESSIBILITY.PUBLIC ? `-${accessibility}` : ''
      const poweringType = capacity !== CHARGINGPOINT_CAPACITY.REGULAR ? `${capacity}-` : ''
      const status = poweringType + group.id + access

      layers.push(
        {
          id: `chargingpoints-${status}`,
          label: statusSlugToLabel(status, localStorage.getItem('language')),
          type: 'planmode',
          // TODO: Make this configurable (passiveLayers group property with default passive statuses)
          visible: group.config.legendCollapseOpen ? accessibility === CHARGINGPOINT_ACCESSIBILITY.PUBLIC : true,
          config: {
            status,
            statusGroup: group.id,
            accessibility,
            capacity,
            iconName: `${capacity}-${group.id}-${accessibility}`,
            iconColor: group.config.iconColor,
            iconType: group.config.iconType,
            phase: group.config.phase,
          },
          restrictions: findRestriction({ group, capacity }),
        },
      )
    })
  })

 return layers
}, [])

export const planmodeIcons = planmodeLayerDetails.map(layer => layer.config.iconName)
