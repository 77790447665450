<template>
  <div class="panel d-flex">
    <aside class="aside flex-shrink-0 mr-3">
      <h3 class="title">
        {{ $t('locationCriteriaPanel.title') }}
      </h3>
      <div class="w-75 mt-3">
        {{ $t('locationCriteriaPanel.description') }}
      </div>
      <div>
        <b-button
          class="mt-3"
          @click="reset"
        >
          {{ $t('locationCriteriaPanel.reset') }}
        </b-button>
      </div>
      <div class="d-flex mt-5">
        <span class="ParticipationPanel__Label mr-3">
          {{ $t('locationCriteriaPanel.isActive') }}
        </span>
        <ToggleSwitch
          v-model="data.showLocationCriteria"
        />
      </div>
    </aside>

    <div class="main flex-grow-1 flex-shrink-1">
      <div class=" align-items-center">
        <vSelect
          ref="criteriaSelect"
          :value="activeCriteria"
          multiple
          taggable
          uid
          :disabled="selectDisabled"
          :description="$t('locationCriteriaPanel.criteria.description')"
          :options="passiveCriteria"
          :is-c-r-u-d="true"
          :autofocus="true"
          :menu-actions="criteriaMenuActions"
          :select-group-label="$t('locationCriteriaPanel.criteria.title')"
          @option:selecting="handleAddItem"
          @option:deselecting="handleDeactivateItem"
          @option:created="handleCreateNewItem"
          @delete-item="handleDeleteItem"
          @deactivate-item="handleDeactivateItem"
          @rename-item="handleRenameItem"
          @select-disabled="selectDisabled = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from '@/components/form/vSelect'
import ToggleSwitch from '@/components/form/ToggleSwitch'

export default {
  name: 'LocationCriteriaPanel',
  components: { vSelect, ToggleSwitch },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      data: {
        showLocationCriteria: false,
        locationCriteria: [],
      },
      trackChanges: true,
      initialDataValues: null,
      activeCriteria: [],
      passiveCriteria: [],
      criteriaMenuActions: {
        deactivate: {
          label: this.$i18n.t('locationCriteriaPanel.criteria.deactivate'),
          icon: 'close-light',
          isButton: true,
        },
        rename: {
          label: this.$i18n.t('locationCriteriaPanel.criteria.rename'),
          icon: 'pencil',
          isButton: false,
          hasFormInput: true,
        },
        delete: {
          label: this.$i18n.t('locationCriteriaPanel.criteria.delete'),
          icon: 'trash-alt-regular',
          isButton: true,
          possibleLock: true,
        },
      },
      selectDisabled: false,
      difference: false,
    }
  },

  computed: {
    ...mapGetters('config', ['getLocationCriteria', 'getDefaultLocationCriteria', 'getShowLocationCriteria']),
  },

  watch: {
    data: {
      handler(data) {
        this.activeCriteria = data.locationCriteria.filter(criteria => criteria.active)
        this.passiveCriteria = data.locationCriteria.filter(criteria => !criteria.active)

        this.difference = this.initialDataValues ? JSON.stringify(data) !== JSON.stringify(this.initialDataValues) : false

        this.$emit('input', { data: this.data, difference: this.difference })
        this.initialDataValues = JSON.parse(JSON.stringify(this.data))
      },
      deep: true,
    },
  },
  created() {
    this.setValues()
  },

  methods: {
    async setValues(reset = false) {
      this.data.showLocationCriteria = this.getShowLocationCriteria
      this.data.locationCriteria = reset ? JSON.parse(JSON.stringify(this.getDefaultLocationCriteria	)) : JSON.parse(JSON.stringify(this.getLocationCriteria	))
      if (reset) return
      this.initialDataValues = JSON.parse(JSON.stringify(this.data))
    },

    handleCreateNewItem (event) {
      const label = event.label || event
      if (this.data.locationCriteria.some(criteria => criteria.label === label)) return

      this.data.locationCriteria.push({ label, active: true })

      // Set focus on input after creating a new criteria //
      this.$nextTick(() => {
        this.$refs.criteriaSelect.$children?.[0]?.$refs?.search?.focus()
      })
    },
    handleAddItem (event) {
      const index = this.data.locationCriteria.findIndex(criteria => criteria.uuid === event.uuid)
      if (index !== -1) {
        this.data.locationCriteria[index].active = true
      }
    },
    handleRenameItem ({ selectedItem, newItemLabel }) {
      const index = this.data.locationCriteria.findIndex(criteria => criteria.uuid === selectedItem.uuid)
      if (index !== -1) {
        if (newItemLabel) {
          this.data.locationCriteria[index].label = newItemLabel
        }
      }
    },
    handleDeactivateItem ({ selectedItem }) {
      const index = this.data.locationCriteria.findIndex(criteria => criteria.label === selectedItem.label)
      this.data.locationCriteria[index].active = false
    },
    handleDeleteItem ({ selectedItem }) {
      if (selectedItem.isLockedForDelete) return

      const index = this.data.locationCriteria.findIndex(criteria => criteria.label === selectedItem.label)
      this.data.locationCriteria.splice(index, 1)
    },
    reset() {
      this.setValues(true)
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.vs__selected-options) {
  display: block;
}
.panel {
  .aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }

  .main {
    font-size: 1.25rem;
  }

  .label {
    flex-shrink: 0;
    width: 175px;
  }
}
</style>
