const statusesWithLabelDutch = {
  'alert': 'Aandachtspunt',
  'rejected': 'Afgekeurd',
  'rejected-semipublic': 'Afgekeurd (SP)',
  'rejected-private': 'Afgekeurd privaat',
  'suggestion': 'Voorgesteld',
  'suggestion-semipublic': 'Voorgesteld (SP)',
  'suggestion-private': 'Voorgesteld privaat',
  'definitive': 'Gevalideerd',
  'definitive-semipublic': 'Gevalideerd (SP)',
  'definitive-private': 'Gevalideerd privaat',
  'in-progress': 'In voorbereiding',
  'in-progress-semipublic': 'In voorbereiding (SP)',
  'in-progress-private': 'In voorbereiding private',
  'realized': 'Gerealiseerd',
  'realized-semipublic': 'Gerealiseerd (SP)',
  'realized-private': 'Gerealiseerd privaat',
  'fastcharger-realized-semipublic': 'Gerealiseerde snellader (SP)',
  'fastcharger-rejected': 'Afgekeurde snellader',
  'fastcharger-rejected-semipublic': 'Afgekeurde snellader (SP)',
  'fastcharger-rejected-private': 'Afgekeurde snellader privaat',
  'fastcharger-realized': 'Gerealiseerde snellader',
  'fastcharger-realized-private': 'Gerealiseerde snellader privaat',
  'fastcharger-suggestion': 'Voorgestelde snellader',
  'fastcharger-suggestion-semipublic': 'Voorgestelde snellader (SP)',
  'fastcharger-suggestion-private': 'Voorgestelde snellader privaat',
  'fastcharger-definitive': 'Gevalideerde snellader',
  'fastcharger-definitive-semipublic': 'Gevalideerde snellader (SP)',
  'fastcharger-definitive-private': 'Gevalideerde snellader privaat',
  'charging-hub-rejected': 'Afgekeurd laadplein',
  'charging-hub-rejected-semipublic': 'Afgekeurd laadplein (SP)',
  'charging-hub-rejected-private': 'Afgekeurd laadplein privaat',
  'charging-hub-suggestion': 'Voorgesteld laadplein',
  'charging-hub-suggestion-semipublic': 'Voorgesteld laadplein (SP)',
  'charging-hub-suggestion-private': 'Voorgesteld laadplein privaat',
  'charging-hub-definitive': 'Gevalideerd laadplein',
  'charging-hub-definitive-semipublic': 'Gevalideerd laadplein (SP)',
  'charging-hub-definitive-private': 'Gevalideerd laadplein privaat',
  'fastcharger-in-progress': 'Snellader in voorbereiding',
  'fastcharger-in-progress-private': 'Snellader in voorbereiding privaat',
  'fastcharger-in-progress-semipublic': 'Snellader in voorbereiding (SP)',
  'charging-hub-in-progress': 'Laadplein in voorbereiding',
  'charging-hub-in-progress-private': 'Laadplein in voorbereiding privaat',
  'charging-hub-in-progress-semipublic': 'Laadplein in voorbereiding (SP)',
  'charging-hub-realized': 'Gerealiseerd laadplein',
  'charging-hub-realized-semipublic': 'Gerealiseerd laadplein (SP)',
  'charging-hub-realized-private': 'Gerealiseerd laadplein privaat',
}

const statusesWithLabelEnglish = {
  'alert': 'Attention',
  'rejected': 'Rejected',
  'rejected-semipublic': 'Rejected (SP)',
  'rejected-private': 'Rejected private',
  'suggestion': 'Suggested',
  'suggestion-semipublic': 'Suggested (SP)',
  'suggestion-private': 'Suggested private',
  'definitive': 'Validated',
  'definitive-semipublic': 'Validated (SP)',
  'definitive-private': 'Validated private',
  'in-progress': 'In progress',
  'in-progress-semipublic': 'In progress (SP)',
  'in-progress-private': 'In progress private',
  'realized': 'Realized',
  'realized-semipublic': 'Realized (SP)',
  'realized-private': 'Realized private',
  'fastcharger-realized-semipublic': 'Realized fastcharger (SP)',
  'fastcharger-rejected': 'Rejected fastcharger',
  'fastcharger-rejected-semipublic': 'Rejected fastcharger (SP)',
  'fastcharger-rejected-private': 'Rejected fastcharger private',
  'fastcharger-realized': 'Realized fastcharger',
  'fastcharger-realized-private': 'Realized fastcharger private',
  'fastcharger-suggestion': 'Suggested fastcharger',
  'fastcharger-suggestion-semipublic': 'Suggested fastcharger (SP)',
  'fastcharger-suggestion-private': 'Suggested fastcharger private',
  'fastcharger-definitive': 'Validated fastcharger',
  'fastcharger-definitive-semipublic': 'Validated fastcharger (SP)',
  'fastcharger-definitive-private': 'Validated fastcharger private',
  'charging-hub-rejected': 'Rejected charging hub',
  'charging-hub-rejected-semipublic': 'Rejected charging hub (SP)',
  'charging-hub-rejected-private': 'Rejected charging hub private',
  'charging-hub-suggestion': 'Suggested charging hub',
  'charging-hub-suggestion-semipublic': 'Suggested charging hub (SP)',
  'charging-hub-suggestion-private': 'Suggested charging hub private',
  'charging-hub-definitive': 'Validated charging hub',
  'charging-hub-definitive-semipublic': 'Validated charging hub (SP)',
  'charging-hub-definitive-private': 'Validated charging hub private',
  'fastcharger-in-progress': 'Fastcharger in progress',
  'fastcharger-in-progress-private': 'Fastcharger in progress private',
  'fastcharger-in-progress-semipublic': 'Fastcharger in progress (SP)',
  'charging-hub-in-progress': 'Charging hub in progress',
  'charging-hub-in-progress-private': 'Charging hub in progress private',
  'charging-hub-in-progress-semipublic': 'Charging hub in progress (SP)',
  'charging-hub-realized': 'Realized charging hub',
  'charging-hub-realized-semipublic': 'Realized charging hub (SP)',
  'charging-hub-realized-private': 'Realized charging hub private',
}

const statusesWithVattenfallLabel = [
  'alert',
  'rejected',
  'suggestion',
  'definitive',
  'in-progress',
  'realized',
  'realized-semipublic',
  'fastcharger-realized-semipublic',
  'fastcharger-rejected',
  'fastcharger-realized',
  'fastcharger-suggestion',
  'fastcharger-definitive',
  'charging-hub-rejected',
  'charging-hub-suggestion',
  'charging-hub-definitive',
  'charging-hub-realized',
  'charging-hub-in-progress',
  'charging-hub-realized-semipublic',
]

const statusGroupLabels = {
  'alert': 'alert',
  'rejected': 'rejected',
  'suggestion': 'suggestion',
  'definitive': 'definitive',
  'in-progress': 'in-progress',
  'realized': 'realized',
}

const statusSlugToLabel = (status, language = 'nl') => {
  if (! status) {
    throw new Error('Invalid status')
  }

  return language === 'nl'
    ? statusesWithLabelDutch[status] ?? 'Onbekend'
    : statusesWithLabelEnglish[status] ?? 'Unknown'
}

const statusSlugToLabelVattenfall = ({ status }) => {
  return statusesWithVattenfallLabel[status] || 'Onbekend'
}

const chargerAccessibilityTypes = {
  public: 'public',
  semipublic: 'semipublic',
  private: 'private',
}

const statusSlugToNumber = ({ status }) => {
  let options = {
    'alert': 0,
    'suggestion': 1,
    'definitive': 2,
    'in-progress': 3,
    'realized': 4,
    'realized-semipublic': 5,
    'rejected': 6,
    'fastcharger-realized-semipublic': 7,
    'fastcharger-rejected': 8,
    'fastcharger-realized': 9,
    'fastcharger-suggestion': 10,
    'fastcharger-definitive': 11,
    'charging-hub-rejected': 12,
    'charging-hub-suggestion': 13,
    'charging-hub-definitive': 14,
    'charging-hub-realized': 15,
    'charging-hub-realized-semipublic': 16,
    'charging-hub-in-progress': 17,
  }
  return options[status] || null
}

/**
 * statuses are a composite of current data and the actual status (think of state)
 */
const determineStatus = ({ chargingpoint, thresholds }) => {
  let status = chargingpoint.data.properties.status

  if (!status) {
    return undefined
  }

  // if status includes already a modifier or is an alert don't try to transform
  const isTransformableStatus = ['fastcharger', 'charging-hub', 'alert'].every(status => ! chargingpoint.data.properties.status.includes(status))
  if (! isTransformableStatus) {
    return status
  }

  // locations with more than 2 connection points are a charging hub, according to RVO: https://www.rvo.nl/sites/default/files/2021/06/Laden%20van%20Elektrische%20Voertuigen%20-%20Definities%20en%20Toelichting%20januari%202021.pdf
  if (chargingpoint.data.properties.nroutlets > thresholds.charginghub) {
    status = `charging-hub-${chargingpoint.data.properties.status}`
  }

  // if power is above threshold and the status is not already fastcharger, update it accordingly
  if (chargingpoint.data.monitoring?.power >= thresholds.fastcharger) {
    status = `fastcharger-${chargingpoint.data.properties.status}`
  }

  return status
}

module.exports = {
  statusesWithLabelDutch,
  statusesWithLabelEnglish,
  statusSlugToLabel,
  statusSlugToNumber,
  statusSlugToLabelVattenfall,
  determineStatus,
  chargerAccessibilityTypes,
  statusGroupLabels,
}
