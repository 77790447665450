<template>
  <div
    class="PlanModeSearch__filterOverlay PMS-Filter"
    :class="{ 'PMS-Filter--active': show }"
  >
    <div class="inner">
      <div class="PMS-Filter__header">
        <span>
          <SvgIcon
            icon="filter-regular"
            class="PMS-Filter__icon"
          />
          <h4 class="PMS-Filter__title">{{ $t('components.sidebar.PlanModeSearch.filter') }}</h4>
        </span>
        <span @click="toggle">
          <SvgIcon
            icon="times-regular"
            class="PMS-Filter__icon PMS-Filter__close"
          />
        </span>
      </div>
      <div class="PMS-Filter__body">
        <div
          v-for="(filter) in filters"
          :key="filter.id"
          class="PMS-Filter-Item"
        >
          <div
            v-if="filter.label"
            class="PMS-Filter-Item__head"
          >
            <SvgIcon :icon="filter.icon" />
            <span v-text="$t(filter.label)" />
          </div>

          <PlanModeFilterMaskRange
            v-if="filter.type === FILTER_TYPE.RANGE_FILTER"
            :filter="filter"
            @blur="handleFilterChangeValue"
            @enter="handleFilterChangeValueEnter"
          />

          <PlanModeFilterMaskToggle
            v-if="filter.type === FILTER_TYPE.TOGGLE_FILTER"
            :filter="filter"
            @input="handleFilterToggle"
            @enter="handleFilterToggleEnter"
          />

          <PlanModeFilterMaskSelect
            v-if="filter.type === FILTER_TYPE.SELECT_FILTER"
            :key="selectComponentKey"
            :filter="filter"
            @input="handleFilterSelect"
            @enter="handleFilterSelectEnter"
          />

          <PlanModeFilterMaskDropdown
            v-if="filter.type === FILTER_TYPE.DROPDOWN_FILTER"
            :filter="filter"
            @input="handleFilterChangeDropdown"
            @enter="handleFilterChangeDropdownEnter"
          />

          <PlanModeFilterMaskButtons
            v-if="filter.type === FILTER_TYPE.BUTTON_FILTER"
            :filter="filter"
            @click="handleFilterChangeButtons"
            @enter="handleFilterChangeButtonsEnter"
          />
        </div>
      </div>
    </div>
    <div class="PMS-Filter__save">
      <div class="mt-2 d-flex justify-content-end">
        <b-button
          type="submit"
          size="sm"
          variant="primary"
          :disabled="!edited"
          @click="handleApply"
        >
          <b-spinner v-if="loading" small variant="light" class="mr-2" />
          <span>{{ $t('components.sidebar.PlanModeSearch.apply') }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import PlanModeFilterMaskRange from '@/components/sidebar/PlanModeFilterMaskRange'
import PlanModeFilterMaskToggle from '@/components/sidebar/PlanModeFilterMaskToggle'
import PlanModeFilterMaskSelect from '@/components/sidebar/PlanModeFilterMaskSelect'
import PlanModeFilterMaskButtons from '@/components/sidebar/PlanModeFilterMaskButtons'
import PlanModeFilterMaskDropdown from '@/components/sidebar/PlanModeFilterMaskDropdown'

import SvgIcon from '@/components/common/SvgIcon'
import { FILTER_TYPE } from '@/../shared/valueholders/filter-types'
import { Bugfender } from '@bugfender/sdk'
import { mapGetters, mapMutations } from 'vuex'
import { isNumeric } from '@/helpers/string'

export default {
  name: 'FilterOverlay',
  components: { PlanModeFilterMaskRange, PlanModeFilterMaskToggle, PlanModeFilterMaskSelect, PlanModeFilterMaskButtons, PlanModeFilterMaskDropdown, SvgIcon },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filters: [],
      edited: false,
      selectComponentKey: 0,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('filters', ['getFilters']),
    ...mapGetters('access', ['getActiveMunicipality']),
  },
  watch: {
    show(next) {
      if (next) {
        this.syncFilters()
      }
    },
    getActiveMunicipality() {
      this.syncFilters()
    },
  },
  created() {
    this.FILTER_TYPE = FILTER_TYPE
  },
  methods: {
    ...mapMutations('filters', ['setFilterOptions']),

    toggle() {
      this.$emit('update:show', !this.show)
    },

    // handle store actions
    handleFilterToggle({ id }) {
      const filter = this.filters.find(filter => filter.id === id)

      if (!filter) {
        return
      }

      this.setLocaleFilter({
        id,
        options: {
          active: !filter.active,
        },
      })
    },

    handleFilterSelect({ selectedFilter }) {
      const filter = this.filters.find(filter => filter.id === selectedFilter.id)

      filter.values = selectedFilter.values
      filter.active = selectedFilter.values.length > 0

      this.edited = true
    },

    handleFilterChangeDropdown({ selectedFilter }) {
      const filter = this.filters.find(filter => filter.id === selectedFilter.id)

      filter.date = selectedFilter.selectedDate
      filter.active = !!selectedFilter.selectedDate

      this.edited = true
    },

    handleFilterChangeButtons({ selectedFilter }){
      const filter = this.filters.find(filter => filter.id === selectedFilter.id)

      filter.state = selectedFilter.state
      filter.active = selectedFilter.state === 'with' || selectedFilter.state === 'without'

      this.edited = true
    },

    handleFilterChangeButtonsEnter({ selectedFilter }) {
      this.handleFilterChangeButtons(selectedFilter)
      this.handleApply()
    },

    handleFilterChangeDropdownEnter({ selectedFilter }) {
      this.handleFilterChangeDropdown(selectedFilter)
      this.handleApply()
    },

    handleFilterSelectEnter({ selectedFilter }) {
      this.handleFilterSelect({ selectedFilter })
      this.handleApply()
    },

    handleFilterChangeValueEnter({ id, key, event }) {
      this.handleFilterChangeValue({ id, key, event })
      this.handleApply()
    },

    handleFilterToggleEnter({ id }) {
      this.handleFilterToggle({ id })
      this.handleApply()
    },

    handleFilterChangeValue({ id, key, event }) {
      const value = event.target.value && isNumeric(event.target.value) ? parseInt(event.target.value) : null

      this.setLocaleFilter({
        id,
        options: {
          // used for range filters, values must be numbers
          [key]: value,
        },
      })
    },

    handleApply() {
      this.loading = true
      setTimeout(() => {
        this.edited = false
        this.applyFilter()
        this.toggle()
        this.loading = false
      }, 500)
    },

    syncFilters() {
      this.filters = Object.values(this.getFilters)
        .filter(filter => filter.visible)
        .map(filter => ({ ...filter }))
    },

    setLocaleFilter({ id, options }) {
      this.edited = true
      const index = this.filters.findIndex(filter => filter.id === id)


      if (index === -1) {
        Bugfender.warn('filter not found', id)
        return
      }

      Object.entries(options).forEach(([key, value]) => {
        this.$set(this.filters[index], key, value)
      })

      if (this.filters[index].type === FILTER_TYPE.RANGE_FILTER) {
        const isComplete = this.filters[index].from !== null && this.filters[index].to !== null

        this.filters[index].active = isComplete
      }
    },

    applyFilter() {
      if (!this.filters) {
        Bugfender.log('no filters to apply')
        return
      }

      this.filters.forEach(filter => this.setFilterOptions({ id: filter.id, options: filter }))
    },
  },
}
</script>

<style lang="scss">
@use 'sass:math';

$hover-color: #4f5c6e;
$PMS-padding: 1.5rem;
$height-bottom: 50px;

.PMS-Filter {
  position: absolute;
  background-color: white;
  display: block;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: left ease-in-out 0.2s;
  color: #2c3e50;

  .inner {
    overflow-y: scroll;
    padding: $PMS-padding 0 ($PMS-padding * 2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid #bbc3ce;
    padding-bottom: 1rem;
    margin-left: $PMS-padding;
    margin-right: $PMS-padding;

    span {
      display: flex;
      align-content: center;
    }
  }

  &__body {
    padding: math.div($PMS-padding, 2) $PMS-padding;
  }

  &__title {
    display: inline-block;
    margin: 0;
    margin-left: 0.5rem;
    font-size: 1.25rem;
  }

  &__save {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px;
    background: white;
  }

  &__icon {
    font-size: 1.3rem;
  }

  &__close {
    cursor: pointer;

    &:hover {
      color: $hover-color;
    }
  }

  &--active {
    left: 0;
  }
}

.PMS-Filter-Item {
  margin-bottom: .5rem;

  &:first-child {
    .PMS-Filter-Item__head {
      margin-top: 0;
    }
  }

  &__head {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;

    .svg-container {
      font-size: 1.3rem;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    input {
      width: 5ch;
      flex-grow: 1;
      flex-shrink: 1;
    }

    span,
    input {
      margin-left: 0.5rem;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
